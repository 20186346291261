
import { defineComponent, ref } from 'vue'

import CategoriesPhotoList from '@/components/photosGoods/CategoriesPhotoList.vue'
import PhotoListSkeleton from '@/components/photosGoods/PhotoListSkeleton.vue'
import { db } from '@/main'

export default defineComponent({
  name: 'PhotoGallery',
  components: {
    CategoriesPhotoList,
    PhotoListSkeleton
  },
  setup () {
    const categories = ref([])
    db.collection('good').onSnapshot((snapshotChange) => {
      categories.value = []
      snapshotChange.forEach((doc) => {
        categories.value.push({
          id: doc.id,
          picture: doc.data().picture,
          name: doc.data().name,
          arrayImages: doc.data().arrayImages,
          description: doc.data().description
        })
      })
    })
    return {
      categories
    }
  }
})
